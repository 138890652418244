<template>
  <div
    class="site-layout"
    @scroll="scrollHandler"
    :class="[layoutClasses]"
    style="height: inherit"
  >
    <!-- NAVBAR -->
    <b-navbar
      :toggleable="false"
      class="header-navbar align-items-center navbar-brand-center navbar-brand-left justify-content-between"
    >
      <slot name="navbar" :toggleVerticalMenuActive="toggleVerticalMenuActive">
        <div class="navbar-header">
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <b-link class="navbar-brand" to="/">
                <span class="brand-text">
                  <b-img
                    src="@/@core/assets/images/cpacer.svg"
                    alt="logo"
                  />
                </span>
              </b-link>
            </li>
          </ul>
        </div>
        <div>
        </div>
          <b-navbar-nav class="nav align-items-center login">        
                  <b-link v-if="!User.isAuth()" class="login-block d-flex align-items-center" :to="{ name: 'auth-login' }">
                      <span class="mr-1">Войти</span>
                      <div class="login-img">
                        <feather-icon icon="ArrowUpRightIcon" style="color: #000;" size="12" />
                      </div>
                  </b-link>
                  <b-link v-else class="login-block d-flex align-items-center" :to="{ name: 'user-dashboard' }">
                      <span class="mr-1 text-nowrap">В кабинет</span>
                      <div class="login-img">
                        <feather-icon icon="ArrowUpRightIcon" style="color: #000;" size="12" />
                      </div>
                  </b-link>
          </b-navbar-nav>
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />

    <!-- /Vertical Nav Menu Overlay -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition :name="routerTransition" mode="out-in">
      <component
        :is="layoutContentRenderer"
        :key="
          layoutContentRenderer === 'layout-content-renderer-left'
            ? $route.meta.navActiveLink || $route.name
            : null
        "
      >
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- <app-footer /> -->
    <slot name="customizer" />
  </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import AppNavbarLandingLayout from "@core/layouts/components/app-navbar/AppNavbarLandingLayout.vue";
import AppNavbarHorizontalLayoutBrand from "@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue";
import AppFooter from "@/components/AppFooter.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { BNavbar } from "bootstrap-vue";
import { useScrollListener } from "@core/comp-functions/misc/event-listeners";

import { onUnmounted } from "@vue/composition-api";

// Content Renderer
import LayoutContentRendererDefault from "@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue";
import LayoutContentRendererLeft from "@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue";
import LayoutContentRendererLeftDetached from "@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue";
import useLayoutHorizontal from "./useLayoutHorizontal";
import HorizontalNavMenu from "./components/horizontal-nav-menu/HorizontalNavMenu.vue";

// Vertical Menu
/* eslint-disable import/order */
import VerticalNavMenu from "@core/layouts/layout-site/components/vertical-nav-menu/VerticalNavMenu.vue";
import useVerticalLayout from "@core/layouts/layout-vertical/useVerticalLayout";
import mixinLayoutHorizontal from "./mixinLayoutHorizontal";
/* eslint-enable import/order */

import User from "@/modules/user/";

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight + 100 || document.documentElement.clientHeight) &&
    rect.right <=
      (window.innerWidth + 100 || document.documentElement.clientWidth)
  );
}

export default {
  data() {
    return {
      User
    }
  },
  methods: {
    scrollHandler(event) {
      let $elements = document.getElementsByClassName("q-section-block");
      let prevBlocks = window.innerHeight;

      $elements.forEach((el) => {
        //                if( isElementInViewport(el) === false ) return;

        var rect = el.getBoundingClientRect();
        var scrollTop = event.target.scrollingElement
          ? event.target.scrollingElement.scrollTop
          : event.target.scrollTop;

        if (el.offsetTop < scrollTop) {
          let translate = (scrollTop - el.offsetTop) / 4 - 100;

          if (translate < 0) {
            translate = 0;
          } else if (translate > 100) {
            translate = 100;
          }

          let scale = 1 - (2 - rect.height / (scrollTop - el.offsetTop)) / 8;

          if (scale > 1) {
            scale = 1;
          } else if (scale < 0.9) {
            scale = 0.9;
          }

          el.style.transform = `scale(${scale}, ${scale}) translateY(${translate}px)`;
        } else {
          el.style.transform = "scale(1, 1)";
        }
      });

      this.handleAnimations();
    },

    handleAnimations() {
      let $animations = document.getElementsByClassName("animate__animated");

      $animations.forEach((el) => {
        if (isElementInViewport(el) || el.dataset.name) {
          if (el.dataset.anime) {
            let delay = el.dataset.delay ? parseInt(el.dataset.delay) : 0;
            setTimeout(() => {
              el.classList.add(el.dataset.anime);
              el.style.opacity = "1";
              //                           setTimeout(() => {
              //                               el.classList.remove('animate__animated');
              //                           }, 1000 );
            }, delay);
          }
        }
      });
    },
  },

  created() {
    document.body.style.overflowY = "scroll";
    window.addEventListener("scroll", this.scrollHandler);
    document.body.addEventListener("scroll", this.scrollHandler);
  },

  destroyed() {
    document.body.style.overflowY = "";
    window.removeEventListener("scroll", this.scrollHandler);
    document.body.removeEventListener("scroll", this.scrollHandler);
  },

  mounted() {
    setTimeout(
      () => {
        this.handleAnimations();
        sessionStorage.setItem("loaded", 1);
      },
      sessionStorage.getItem("loaded") ? 250 : 3500
    );
  },

  components: {
    AppBreadcrumb,
    AppNavbarLandingLayout,
    AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,

    BNavbar,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,

    // Vertical Menu
    VerticalNavMenu,
  },
  mixins: [mixinLayoutHorizontal],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left")
        return "layout-content-renderer-left";
      if (rendererType === "sidebar-left-detached")
        return "layout-content-renderer-left-detached";
      return "layout-content-renderer-default";
    },
  },
  setup() {
    const { skin, navbarType, footerType, routerTransition, isNavMenuHidden } =
      useAppConfig();

    // Vertical Menu
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
      resizeHandler,
    } = useVerticalLayout(navbarType, footerType);

    // Resize handler
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    });

    const { navbarMenuTypeClass, layoutClasses, footerTypeClass } =
      useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive);

    // Scroll Listener
    const { scrolledTo } = useScrollListener();

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
    };
  },
};
</script>

<style lang="scss">
body {
  overflow-y: scroll;
}

@import "~@core/scss/base/themes/bordered-layout.scss";
@import "~@core/scss/site.scss";
</style>
