<template>
    <div class="footer" style="height: auto;">    
          <span style="color:#FFF">тут будет подвал</span>
    </div>
</template>

<script>


    export default {

        data() {
            return {
                modalsVisibility: {
                    callback: false,
                },
            }
        },
        methods: {
            showModal(action) {
                Object.keys(this.modalsVisibility).forEach((key) => {
                    this.modalsVisibility[key] = false;
                });

                if (this.modalsVisibility.hasOwnProperty(action)) {
                    this.modalsVisibility[action] = true;
                }
            },
            closeModal(action) {
                Object.keys(this.modalsVisibility).forEach((key) => {
                    this.modalsVisibility[key] = false;
                });
            },
        },
        components: {
        },
        watch: {

        },
        mounted() {
        }

    }

</script>