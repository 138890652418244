export default [
        
    {
        title: 'Главная',
        route: 'user-dashboard',
    },
    {
        title: 'Статистика',
        route: 'user-statistics',
    },
    {
        title: 'Финансы',
        route: 'user-finance',
    },
    {
        title: 'Заявки',
        route: 'user-orders',
    },
    {
        title: 'Команда',
        route: 'user-team',
    },
    // {
    //     title: 'Документы',
    //     route: 'user-documents',
    // },
 
]