<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
  
    <!-- Left Col -->
    <div class="align-items-center flex-grow-1 d-none d-lg-flex justify-content-between ml-5">
       <b-navbar-nav>
        <!-- <b-nav-item @click="scrollToElement('how-it-works')" href="#">Как работает?</b-nav-item>
        <b-nav-item @click="scrollToElement('futures')"  href="#">Наши преимущества</b-nav-item>
        <b-nav-item :to="{ name: 'user-contracts-rent' }">Аренда хешрейта</b-nav-item>
        <b-nav-item :to="{ name: 'user-miners-buy' }"  href="#">Майнеры</b-nav-item> -->
        <b-nav-item :to="{ name: '' }"  href="#">тут будет меню</b-nav-item>
      </b-navbar-nav>
    </div>
    
    <ul class="nav navbar-nav d-xl-none ml-auto ">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    
    <!-- Right Col -->
    <showAt breakpoint="large">
        <b-navbar-nav class="nav align-items-center ml-auto login" style="background-color: #FFF; border-radius: 50px; height: 36px;">        
                <b-nav-item class="m-0 p-0">
                    <feather-icon icon="UserIcon" style="color: #000; margin-right: 4px;" size="20" />
                </b-nav-item>
                <b-nav-item :to="{ name: 'auth-login' }">
                   Вход
                </b-nav-item>
                <b-nav-item class="m-0 p-0">
                    /
                </b-nav-item>
                <b-nav-item :to="{ name: 'auth-register' }">
                   Регистрация
                </b-nav-item>
        </b-navbar-nav>
    </showAt>
    
    
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

 import { showAt, hideAt } from 'vue-breakpoints'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    showAt
  },
  
  methods: {
      
      scrollToElement( elID ) {
          return this.$smoothScroll({
            scrollTo: document.getElementById(elID),
            updateHistory: false,
            hash: `#${elID}`  // required if updateHistory is true
          });
      }
      
  },
  
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

}
</script>
