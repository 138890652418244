<template>
  <site-layout>
    <router-view />
  </site-layout>
</template>

<script>
    
import SiteLayout from '@core/layouts/layout-site/LayoutSite.vue'

export default {
  components: {
    SiteLayout,
  },
}
</script>


