export default [
        
  
    {
        title: 'Как это работает',
        scrollTo: 'how-it-works'
    },
    {
        title: 'Преимущества',
        scrollTo: 'futures'
    },
    {
        title: 'Аренда хэшрейта',
        route: 'user-contracts-rent',
    },
    {
        title: 'Майнеры',
        route: 'user-miners-buy',
    }   
]